<template>
  <div>
    <label
      class="mb-1 font-bold"
      :for="name ?? item?.name"
      v-html="label ?? item?.label + (required || item?.required ? '*' : '')"
    />

    <FieldTextarea
      v-model="modelVal"
      :name="name ?? item?.name"
      class="w-full"
      :placeholder="placeholder ?? item?.placeholder"
      :validation="validationRule"
      :rows="rows"
    />
  </div>
</template>

<script lang="ts" setup>
import type { AnySchema } from "yup/lib/schema";
import type { BasicField, InputType } from "~~/types/form";

const props = defineProps<{
  item?: BasicField;
  validation?: string | AnySchema | object;
  modelValue?: string | number;
  name?: string;
  type?: InputType;
  value?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
}>();

const validationRule =
  props.validation ??
  validate(
    props.item?.type ?? props.type,
    props.item?.required ?? props.required
  );

const emit = defineEmits(["update:modelValue"]);
const modelVal = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});
</script>
