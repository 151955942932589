<template>
  <div>
    <textarea
      :value="modelValue"
      class="w-full resize-none border p-2 placeholder:text-g7-blue/50 focus:outline-none focus:ring focus:ring-g7-blue"
      :class="classes + (hasErrors ? ' border-red-500 text-red-300' : '')"
      :placeholder="placeholder"
      :name="name"
      :rows="rows"
      :disabled="disabled"
      :data-cy="dataCy"
      @input="
        emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />
    <ErrorMessage v-if="displayError" />
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import type { AnySchema } from "yup/lib/schema";

interface Props {
  placeholder?: string;
  modelValue?: string | number;
  name?: string;
  required?: boolean;
  rows?: string;
  validation?: string | AnySchema;
  classes?: string;
  disabled?: boolean;
  showError?: boolean;
  dataCy?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: undefined,
  modelValue: undefined,
  name: undefined,
  validation: undefined,
  rows: "5",
  classes: undefined,
  disabled: false,
  showError: true,
  dataCy: undefined,
});

const { meta } = useField(props.name ?? "to-do-name-input", props.validation, {
  syncVModel: true,
});
const hasErrors = computed(() => !meta.valid && meta.touched);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const displayError = computed(() => hasErrors.value && props.showError);
</script>
